import React from 'react'
import PropTypes from 'prop-types'
import { BorderMain } from './index.style'
import { colors } from '../../styles/vars/colors.style'

const Border = ({ align = 'top', color = colors.dark }) => (
  <BorderMain align={align} color={color}></BorderMain>
)

Border.propTypes = {
  align: PropTypes.oneOf(['top', 'bottom']),
  color: PropTypes.string,
}

export default Border
