import React from 'react'
import PropTypes from 'prop-types'
import { InputLabel, InputMain, InputWrapper } from './index.style'
import { colors } from '@styles/vars/colors.style'
import Spacer from '@components/Spacer'

const Input = ({
  type,
  value,
  onChange,
  label,
  hideLabel,
  id,
  name,
  placeholder,
  borderColor = colors.dark,
  required = false,
}) => {
  return (
    <InputWrapper>
      <InputLabel htmlFor={id} hideLabel={hideLabel}>
        {label}
      </InputLabel>

      {!hideLabel && <Spacer size={8} />}

      <InputMain
        type={type}
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        borderColor={borderColor}
        required={required}
      />
    </InputWrapper>
  )
}

Input.propTypes = {
  type: PropTypes.oneOf(['text', 'email', 'number']).isRequired,
  label: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  borderColor: PropTypes.string,
  hideLabel: PropTypes.bool,
  required: PropTypes.bool,
}

export default Input
