import React from 'react'
import PropTypes from 'prop-types'
import { useStore } from '../../../Store'
import { useInView } from 'react-intersection-observer'
import { AnimateSlideInContent } from './index.style'
import { animation } from '@styles/vars/animation.style'

const AnimateSlideIn = ({
  direction = 'bottom',
  duration = 0.2,
  delay = 0,
  triggerOnce = true,
  animate = true,
  children,
}) => {
  const [ref, inView] = useInView({
    triggerOnce,
    rootMargin: animation.rootMargin,
  })
  const [store] = useStore()
  const { showPageMask } = store

  return (
    <AnimateSlideInContent
      ref={ref}
      inView={inView && animate && !showPageMask}
      direction={direction}
      duration={duration}
      delay={delay}
    >
      {children}
    </AnimateSlideInContent>
  )
}

AnimateSlideIn.propTypes = {
  children: PropTypes.node.isRequired,
  delay: PropTypes.number,
  duration: PropTypes.number,
  triggerOnce: PropTypes.bool,
  animate: PropTypes.bool,
  direction: PropTypes.oneOf(['top', 'right', 'left', 'bottom']),
}

export default AnimateSlideIn
