import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function Seo({ pathname = '', title, description, imageUrl, meta = [] }) {
  const {
    contentfulGlobals: {
      siteUrl,
      seoSiteTitle,
      seoSiteDescription: { seoSiteDescription },
      seoShareImage,
    },
  } = useStaticQuery(
    graphql`
      query {
        contentfulGlobals {
          siteUrl
          seoSiteTitle
          seoSiteDescription {
            seoSiteDescription
          }
          seoShareImage {
            fixed {
              src
            }
          }
        }
      }
    `
  )

  const metaTitle = title ? `${title} | ${seoSiteTitle}` : seoSiteTitle
  const metaDescription = description || seoSiteDescription
  const metaImage = imageUrl || seoShareImage.fixed.src
  const url = `${siteUrl}/${pathname}`
  const metaTags = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: metaTitle,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:image`,
      content: metaImage,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:url`,
      content: url,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ]

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={metaTitle}
      meta={[...metaTags, ...meta]}
    />
  )
}

Seo.propTypes = {
  pathname: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
}

export default Seo
