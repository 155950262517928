import { colors } from '@styles/vars/colors.style'
import { getStyles, textStylesConfig } from '@styles/vars/textStyles.style'
import { setScreenReaderOnlyStyles } from '@utils/utils'
import styled from 'styled-components'

export const InputWrapper = styled.div``

export const InputLabel = styled.label`
  display: block;

  ${props => props.hideLabel && setScreenReaderOnlyStyles()}
`

export const InputMain = styled.input`
  width: 100%;
  padding: 1.2rem 1.6rem;
  ${getStyles(textStylesConfig.body)}
  color: ${colors.dark};
  border-radius: 0.4rem;
  border: 0.1rem solid ${props => props.borderColor};

  ::placeholder {
    color: ${colors.dark};
  }
`
