import React from 'react'
import PropTypes from 'prop-types'
import {
  ButtonContent,
  ButtonMain,
  ButtonIcon,
  ButtonText,
} from './index.style'
import PageTransitionLink from '../PageTransitionLink'

/**
 *
 * @prop type
 * Defines the markup and functionality
 * @prop variant
 * Defines the visual style
 * @prop size
 * Defines the visual size
 * @prop href
 * If defined this renders the component as an anchor element with an href attribute <a href={href} />
 * @prop to
 * If type is internalLink this is the url used in the PageTransitionLink component
 * @prop label
 * If defined this adds an aria-label to the component. Necessary for icon only buttons.
 */

const Button = ({
  type,
  variant,
  disabled,
  href,
  to,
  label,
  iconLeft,
  iconRight,
  onClick,
  children,
  ...props
}) => (
  <>
    {type === 'button' && (
      <ButtonMain
        as="button"
        variant={variant}
        disabled={disabled}
        aria-label={label}
        onClick={onClick}
        {...props}
      >
        <ButtonContent>
          {iconLeft && <ButtonIcon left>{iconLeft}</ButtonIcon>}
          {children && <ButtonText>{children}</ButtonText>}
          {iconRight && <ButtonIcon right>{iconRight}</ButtonIcon>}
        </ButtonContent>
      </ButtonMain>
    )}

    {type === 'submit' && (
      <ButtonMain
        as="input"
        type="submit"
        variant={variant}
        disabled={disabled}
        aria-label={label}
        onClick={onClick}
        value="Submit"
        {...props}
      />
    )}

    {type === 'externalLink' && (
      <ButtonMain
        as="a"
        href={href}
        variant={variant}
        aria-label={label}
        onClick={onClick}
        {...props}
      >
        <ButtonContent>
          {iconLeft && <ButtonIcon left>{iconLeft}</ButtonIcon>}
          {children && <ButtonText>{children}</ButtonText>}
          {iconRight && <ButtonIcon right>{iconRight}</ButtonIcon>}
        </ButtonContent>
      </ButtonMain>
    )}

    {type === 'internalLink' && (
      <PageTransitionLink to={to} onClick={onClick}>
        <ButtonMain
          as="div"
          variant={variant}
          disabled={disabled}
          aria-label={label}
          {...props}
        >
          <ButtonContent>
            {iconLeft && <ButtonIcon left>{iconLeft}</ButtonIcon>}
            {children && <ButtonText>{children}</ButtonText>}
            {iconRight && <ButtonIcon right>{iconRight}</ButtonIcon>}
          </ButtonContent>
        </ButtonMain>
      </PageTransitionLink>
    )}
  </>
)

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit', 'internalLink', 'externalLink'])
    .isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  size: PropTypes.oneOf(['small', 'medium']),
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  href: function (props, propName) {
    if (
      props['type'] === 'externalLink' &&
      (props[propName] === undefined || typeof props[propName] !== 'string')
    ) {
      return new Error('Please provide a href prop!')
    }
  },
  to: function (props, propName) {
    if (
      props['type'] === 'internalLink' &&
      (props[propName] === undefined || typeof props[propName] !== 'string')
    ) {
      return new Error('Please provide a to prop!')
    }
  },
  label: PropTypes.string,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  onClick: function (props, propName) {
    if (
      props['type'] === 'button' &&
      (props[propName] === undefined || typeof props[propName] !== 'function')
    ) {
      return new Error('Please provide an onClick function!')
    }
  },
  children: PropTypes.node,
}

Button.defaultProps = {
  type: 'button',
  variant: 'primary',
  size: 'medium',
}

export default Button
