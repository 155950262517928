import styled, { css } from 'styled-components'
import { font } from '../../styles/vars/font.style'
import { colors } from '../../styles/vars/colors.style'
import { clamp } from '../../styles/utils/conversion.style'
import { easings } from '../../styles/vars/easings.style'

export const ButtonMain = styled.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 4.4rem;
  min-height: 4.4rem;
  padding: 0.3em 0.6em;
  cursor: pointer;
  font-family: ${font.secondary.family};
  ${clamp('font-size', 20, 24)}
  line-height: 1.3;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  border: 0.1rem solid;
  border-radius: 0.3em;
  transition-property: background-color, border-color, color;
  transition-duration: 0.2s;
  transition-timing-function: ${easings.inOut.default};

  &[disabled] {
    opacity: 0.5;
    cursor: default;
    color: ${colors.light};
    background-color: ${colors.dark};
    border-color: ${colors.midGrey};
  }

  ${({ variant }) => {
    if (variant === 'primary') {
      return ButtonPrimary
    }

    if (variant === 'secondary') {
      return ButtonSecondary
    }

    if (variant === 'tertiary') {
      return ButtonTertiary
    }
  }}

  path {
    transition-property: fill;
    transition-duration: 0.2s;
    transition-timing-function: ${easings.inOut.default};
  }
`

const ButtonPrimary = css`
  color: ${colors.dark};
  background-color: ${colors.yellow};
  border-color: ${colors.yellow};

  :not(:disabled):hover {
    color: ${colors.dark};
    background-color: ${colors.light};

    path {
      fill: currentColor;
    }
  }
`

const ButtonSecondary = css`
  color: ${colors.dark};
  background-color: ${colors.light};
  border-color: ${colors.yellow};

  :not(:disabled):hover {
    color: ${colors.light};
    background-color: ${colors.dark};
    border-color: ${colors.dark};

    path {
      fill: currentColor;
    }
  }
`

const ButtonTertiary = css`
  color: ${colors.light};
  background-color: ${colors.dark};

  :not(:disabled):hover {
    color: ${colors.dark};
    background-color: ${colors.yellow};
    border-color: ${colors.yellow};

    path {
      fill: currentColor;
    }
  }
`

export const ButtonContent = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

export const ButtonIcon = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1em;
  margin-left: ${props => (props.right ? '0.3em' : undefined)};
  margin-right: ${props => (props.left ? '0.3em' : undefined)};

  svg {
    width: 100%;
  }
`

export const ButtonText = styled.span``
