import React, { useState } from 'react'
import Container from '../Container'
import Grid from '../Grid'
import GridItem from '../GridItem'
import Spacer from '../Spacer'
import Socials from '../Socials'
import PageTransitionLink from '../PageTransitionLink'
import {
  Heading2,
  TextBody,
  TextBodySmall,
  TextBodyXSmall,
} from '../TextStyles'
import {
  FooterColumn,
  FooterCopy,
  FooterLogo,
  FooterMain,
  FooterRow,
  FooterSignup,
} from './index.style'
import Border from '../Border'
import Button from '@components/Button'
import Input from '@components/Input'
import { colors } from '@styles/vars/colors.style'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'

const Footer = () => {
  const [newsletterEmail, setNewsletterEmail] = useState('')

  return (
    <FooterMain>
      <Border align="top" />
      <Spacer size={[40, 80]} />

      <Container>
        <Grid>
          <GridItem tabletP={8} tabletPStart={3} tabletL={6} tabletLStart={4}>
            <AnimateSlideIn>
              <Heading2 as="h2" secondary>
                Receive our Newsletter
              </Heading2>
            </AnimateSlideIn>

            <Spacer size={[16, 24]} />

            <AnimateSlideIn>
              <TextBody>Sign up to receive updates and announcements:</TextBody>
            </AnimateSlideIn>

            <Spacer size={[32, 48]} />

            <AnimateSlideIn>
              <div id="mc_embed_signup">
                <form
                  action="https://kidsofcolour.us20.list-manage.com/subscribe/post?u=65e36afd885da72aed9eb0297&amp;id=c3e5aaecc4"
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  target="_blank"
                  noValidate
                >
                  <div id="mc_embed_signup_scroll">
                    <FooterSignup>
                      <Input
                        type="email"
                        name="EMAIL"
                        id="mce-EMAIL"
                        value={newsletterEmail}
                        onChange={e =>
                          setNewsletterEmail(e.currentTarget.value)
                        }
                        placeholder="Email address"
                        label={<TextBody>Email Address</TextBody>}
                        borderColor={colors.yellow}
                        hideLabel
                      />

                      {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                      <div
                        style={{ position: 'absolute', left: '-5000px' }}
                        aria-hidden="true"
                      >
                        <input
                          type="text"
                          name="b_65e36afd885da72aed9eb0297_c3e5aaecc4"
                          tabIndex="-1"
                        />
                      </div>

                      <Button
                        type="submit"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                      />
                    </FooterSignup>
                  </div>
                </form>
              </div>
            </AnimateSlideIn>

            <Spacer size={24} />

            <AnimateSlideIn>
              <TextBodyXSmall>
                You can unsubscribe from these communications at any time.
              </TextBodyXSmall>
            </AnimateSlideIn>

            <Spacer size={[40, 64]} />
          </GridItem>
        </Grid>

        <hr />

        <Spacer size={[32, 40]} />

        <FooterRow>
          <FooterColumn>
            <FooterLogo>
              <PageTransitionLink to="">Home</PageTransitionLink>
            </FooterLogo>
          </FooterColumn>

          <FooterColumn>
            <Socials theme="light" />
          </FooterColumn>

          <FooterColumn>
            <FooterCopy>
              <TextBodySmall>
                Copyright © {new Date().getFullYear()} Kids of Colour
              </TextBodySmall>
            </FooterCopy>
          </FooterColumn>
        </FooterRow>

        <Spacer size={[46, 68]} />
      </Container>
    </FooterMain>
  )
}

export default Footer
