import styled from 'styled-components'
import { colors } from '../../styles/vars/colors.style'
import LogoImage from '@images/logo.png'
import { PageTransitionLinkMain } from '../PageTransitionLink/index.style'
import { mq } from '../../styles/vars/media-queries.style'
import { InputWrapper } from '@components/Input/index.style'

export const FooterMain = styled.footer`
  position: relative;
  text-align: center;
  color: ${colors.light};
  background-color: ${colors.dark};
`

export const FooterRow = styled.div`
  ${mq.tabletP} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const FooterColumn = styled.div`
  flex: 1;

  & + & {
    ${mq.tabletPMax} {
      margin-top: 4rem;
    }
  }
`

export const FooterLogo = styled.div`
  flex: 1;
  width: 8rem;
  aspect-ratio: 1;
  background-image: url(${LogoImage});
  background-position: center;
  background-size: contain;

  ${mq.tabletPMax} {
    margin-left: auto;
    margin-right: auto;
  }

  ${PageTransitionLinkMain} {
    width: 100%;
    height: 100%;
    white-space: nowrap;
    text-indent: 200%;
    overflow: hidden;
    border-radius: 50%;
  }
`

export const FooterCopy = styled.div`
  text-align: center;

  ${mq.tabletP} {
    text-align: right;
  }
`

export const FooterSignup = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.8rem -0.8rem -0.8rem;

  > * {
    flex: 1 1 10ch;
    margin: 0.8rem;
  }

  ${InputWrapper} {
    flex: 2 1 30ch;
  }
`
